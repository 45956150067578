class MobileHeader {
    constructor() {
        this.trigger = document.querySelector('.js-mobile-header-trigger');
        this.isMobileShown = false;
        this.mobileHeader = document.querySelector('.js-mobile-header');

        if (this.trigger && this.mobileHeader) {
            this.initEvents();
        }
    }

    initEvents() {
        this.trigger.addEventListener('click', () => {
            if (!this.isMobileShown) {
                this.showMobileHeader();
            } else {
                this.hideMobileHeader();
            }
        });
    }

    showMobileHeader() {
        this.mobileHeader.classList.add('active');

        document.querySelector('body').style.overflow = 'hidden';
        this.trigger.src = '/site/assets/images/cross.svg';
        this.isMobileShown = true;
    }

    hideMobileHeader() {
        this.mobileHeader.classList.remove('active');

        document.querySelector('body').style.overflow = 'auto';
        this.trigger.src = '/site/assets/images/burger-menu.svg';
        this.isMobileShown = false;
    }
}

const mobileHeader = {
    init() {
        this.mobileHeader = new MobileHeader();
    },
};

export default mobileHeader;
