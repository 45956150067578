// ES6 Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// LazyLoading
import 'lazysizes';

import smoothAnchorScroll from './modules/smoothAnchorScroll';
import mobileHeader from './modules/mobileHeader';
import lottie from './modules/lottie';
import modal from './modules/modal';

window.onload = () => {
    smoothAnchorScroll.init();
    mobileHeader.init();
    lottie.init();
    modal();
};
