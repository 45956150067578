import mobileHeader from './mobileHeader';

class SmoothAnchorScroll {
    constructor(paddingTop = 250) {
        this.location = window.location;
        this.paddingTop = paddingTop;
        this.hash = null;

        this.registerEventListeners();

        if (this.location.hash) {
            this.hash = this.location.hash;
        }

        const manualAnchor = document.querySelector('#js-manual-scroll-anchor');
        if (manualAnchor) {
            this.hash = '#js-manual-scroll-anchor';
        }

        if (!this.hash) {
            return;
        }

        this.scrollToAnchor();
    }

    registerEventListeners() {
        const anchors = document.querySelectorAll('.js-click-scroll-anchor');
        anchors.forEach((item) => {
            item.addEventListener('click', this.handleClick.bind(this));
        });
    }

    handleClick(event) {
        event.preventDefault();

        if (event.target.href.substring(0, event.target.href.indexOf('#'))
            !== this.location.href.substring(0, this.location.href.indexOf('#'))) {
            window.location = event.target.href;
        }

        if (mobileHeader.mobileHeader.isMobileShown) {
            mobileHeader.mobileHeader.hideMobileHeader();
        }

        this.hash = SmoothAnchorScroll.getHashFromUrlString(event.currentTarget.href);

        this.addHashToUrl();
        this.scrollToAnchor();
    }

    addHashToUrl() {
        window.history.pushState(null, null, this.hash);
    }

    scrollToAnchor() {
        const anchor = document.querySelector(this.hash);
        if (!anchor) {
            return;
        }

        const {top} = anchor.getBoundingClientRect();
        const scrollTop = top + window.scrollY - this.paddingTop;

        window.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
        });
    }

    static getHashFromUrlString(url) {
        return url.substring(url.indexOf('#'));
    }
}

const smoothAnchorScroll = {
    init() {
        // skip for IE or Edge
        if (document.documentMode || /Edge/.test(navigator.userAgent)) return;

        this.smoothAnchor = new SmoothAnchorScroll();
    },
};

export default smoothAnchorScroll;
