import lottie from 'lottie-web';

class Lottie {
    constructor() {
        lottie.loadAnimation({
            container: document.querySelector('.js-lottie-wrapper'), // the dom element that will contain the animation
            loop: true,
            autoplay: true,
            path: 'data.json',
            rendererSettings: {
                scaleMode: 'noScale',
            },
        });
    }
}

const lottieAnimation = {
    init() {
        this.mobileHeader = new Lottie();
    },
};

export default lottieAnimation;
